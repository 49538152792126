import openBlueBracket from "../assets/openBlueBracket.png";
import closeBlueBracket from "../assets/closeBlueBracket.png";

export const StartGame = ({restart}) => {
  return (
    <div className='start-game'>
      <div className='start-game-content'>
        <div className='start-game-header'>
          <img src={openBlueBracket} className='bracket'/>
          Start Game?
          <img src={closeBlueBracket} className='bracket'/>
        </div>
        <div className='start-game-button' onClick={restart}>Yes</div>
      </div>
    </div>
  )
}
