import {useEffect, useRef} from 'react';

export function useInterval(callback, delay, shouldCancel) {

  const callbackRef = useRef();

  useEffect( ()=> {
    callbackRef.current = callback;
  }, [callback]);

  useEffect( ()=> {
    const interval = setInterval(()=> callbackRef.current(), delay);
    if (shouldCancel) clearInterval(interval);
    return () => clearInterval(interval);
  }, [delay, shouldCancel]);

}